<template>
  <div class="guide-cnt7">
    <h6 class="h6">定制流程</h6>
    <div class="cnt">
      <p class="p">咨询客服，报价下单</p>
      <p class="p">提供设计，出效果图</p>
      <p class="p">校对定稿，买家确认</p>
      <p class="p">定稿完成，安排下单</p>
      <p class="p">生产质检，装箱发货</p>
      <p class="p">收到好评，售后回访</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'guide-cnt7',
  props: {
    msg: String
  },
  methods:{
  },
  mounted(){
  }
}
</script>
<style  lang="scss" scoped>
.guide-cnt7 {
  width: 946px;float:right;background: #fff;border-radius: 5px;min-height: 600px;
  .h6 {
    height: 68px;border-bottom:1px solid #f2f2f2;font-weight: normal;font-size: 22px;line-height: 68px;padding-left:20px;
  }
  .cnt {
    padding:15px;
    .p {
      font-size: 14px;color:#333;margin-bottom:10px;
    }
  }
}
</style>
